.auth-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.sing-up-wrapper {
  margin-top: 180px;
  width: 430px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    width: 93vw;
    margin-top: 0;
  }

  .auth-button-sign-in-up {
    margin-top: -15px;
  }

  .logo {
    height: 32px;
    align-self: flex-start;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .email {
    color: $success;
    font-size: 14px;
    font-weight: 700;
  }
}
.register-organization-tab-wrapper {
  overflow: hidden;
  form {
    display: flex;
    flex-direction: column;
  }
}

.auth-button {
  button {
    width: 100%;
  }
}
