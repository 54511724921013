.create-app-step-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .create-app-intro-wrapper {
    min-width: 788px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    .create-app-title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.configuration-heading {
  margin-top: 20px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .configuration-logo {
    height: 46px;
  }

  .configuration-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $sub-heading-color;
  }
}

.configuration-options-wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  .configuration-option {
    width: 550px;
    .configuration-options-buttons {
      margin-bottom: 10px;
      display: flex;
      gap: 40px;
    }
  }

  .configuration-options-toggle {
    display: flex;
    align-items: center;
    gap: 40px;
  }
}

.tabs-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.controls-container {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
