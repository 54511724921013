.mat-form-field {
  display: flex;
  flex-direction: column;
}
.mat-form-label {
  color: $text-secondary-color;
  font-size: 14px;
  line-height: 20px;
}
.form-container {
  padding: 0 74px;
}

.required-asterisk {
  color: $status-inactive-background-color;
  margin-left: 1px;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0px !important;
}

.mat-mdc-form-field-infix {
  height: 48px !important;
}

.mat-mdc-paginator-page-size-select {
  .mat-mdc-form-field-infix {
    height: inherit !important;
  }
}

.mdc-form-field {
  .mdc-label {
    cursor: pointer;
  }
}

.read-only-value {
  margin-top: 8px;
}

::ng-deep {
  mat-form-field {
    .mat-mdc-text-field-wrapper {
      background-color: white !important;
    }
  }
}
