@use '@angular/material' as mat;
@import 'globals';

$custom-primary-palette: (
  50: $primary-ultra-light-color,
  100: $primary-ultra-light-color,
  200: $primary-ultra-light-color,
  300: $primary-light-color-2,
  400: $primary-light-color-2,
  500: $primary-light-color,
  600: $primary-light-color,
  700: $primary-light-color,
  800: $primary-color,
  900: $primary-color,

  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
  ),
);

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

// Global styles
body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  margin: 0;
  height: 100%;
}

html,
body {
  height: 100%;
  margin: 0;

  a {
    color: $primary-light-color;
    text-decoration: none;
    cursor: pointer;
  }
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

$custom-primary: mat.m2-define-palette($custom-primary-palette);
$custom-theme-accent: mat.m2-define-palette(mat.$m2-indigo-palette);
$custom-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

$myapp-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $custom-primary,
      accent: $custom-theme-accent,
      warn: $custom-theme-warn,
    ),
    density: -2,
  )
);

@include mat.all-component-themes($myapp-theme);
