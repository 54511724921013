.nav-link-common {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  min-height: 48px;
  line-height: 48px;

  &:hover {
    background-color: $background-color;
  }

  &.active-link {
    background-color: $active-background-color;
    color: $primary-light-color;
  }

  &.selected {
    color: $primary-light-color;
  }
}

.nav-item-common {
  display: flex;
  flex-direction: column;
  color: $text-color;
  width: 100%;

  .nav-link-wrapper {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    min-height: 48px;
    border-left: 4px solid transparent;
    line-height: 48px;

    &.has-children {
      padding: 0;
    }

    &.selected,
    &.active-link {
      background-color: $active-background-color;
      color: $primary-light-color;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    width: 100%;
    color: inherit;
    padding: 4px 9px;
    margin: 0 10px;
    text-decoration: none;

    &:hover {
      background-color: $background-color;
    }
  }

  .material-icons.nav-icon,
  .material-icons.expand-icon {
    margin-right: 8px;
  }

  .nav-text {
    flex-grow: 1;
    overflow: hidden;
  }

  .expand-icon {
    margin-left: auto;
    transition: transform 0.3s ease;
  }

  .child-items {
    list-style: none;
    padding: 0 0 0 16px;
    margin: 0;
  }
}
.nav-sub-item-common {
  padding: 4px 40px;
  min-height: 48px;
}
