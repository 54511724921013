@import 'variables';

@import './forms';
@import './auth';
@import './navigation';
@import './application';

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $primary-ultra-light-color;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $primary-light-color-2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.primary-button {
  background-color: $primary-color;
  color: white;
  border-radius: 4px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: darken(#0052cc, 10%);
  }
}

.secondary-button {
  background-color: white;
  color: black;
  border-radius: 4px;
  padding: 12px 24px;
  border: 1px solid #babfc3;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
}

.home-card-chart-wrapper {
  min-height: 588px;
  height: 100%;
  flex-grow: 1;
  background-color: $white-page-background-color;
  padding: 10px 15px;

  .chart-title {
    color: $text-secondary-color;
    font-size: 14px;
    line-height: 24px;
  }

  .chart-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.line {
  flex-grow: 1;
  border-bottom: 1px solid $text-color;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-btn {
  position: relative;
  float: right;
  top: -50px;
}

mat-card {
  background-color: white !important;
  box-shadow: 4px 4px 12px 4px $shadow-color !important;
}

.hr {
  color: $text-black-darker-color;
  border-bottom: 0.1px solid $status-pending-background-color;
}

.error-message-wrapper {
  border: 0.5px solid $border-gray-color;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: 170px;
  overflow-y: auto;

  .validation-icon-message-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .error-list {
    display: flex;
    flex-direction: column;
    margin: 5px 0;

    .error-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $text-black-color;
    }
  }
}

.aps-img {
  width: 100%;
  max-width: 50vw;
  max-height: 38vh;
  margin-top: 50px;
}

.selected-file-wrapper {
  margin-top: 10px;
  height: 126px;
  background-color: $primary-light-color-2;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;

  .documents-icon {
    max-height: 44px;
    max-width: 140px;
  }

  .file-info-options-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .file-info {
      display: flex;
      align-items: center;
      gap: 5px;
      color: $sub-heading-color;

      .file-name {
        flex-grow: 1;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        max-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .replace {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $primary-color;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        .close-icon {
          color: $sub-heading-color;
          transform: scale(0.7);
        }
      }
    }

    .file-size {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.individuals-wrapper {
  cursor: pointer;
}

.or-wrapper {
  display: flex;
  width: 100%;
  gap: 10px;
  color: $text-secondary-color;
}

code-input {
  --item-spacing: 8px !important;
  --item-border: 1px solid #49b05c !important;
  --item-border-bottom: 1px solid #49b05c !important;
  --item-border-has-value: none !important;
  --item-border-has-value: 1px solid #49b05c !important;
  --item-border-bottom-has-value: 1px solid #49b05c !important;
  --item-border-focused: none !important;
  --item-border-focused: 1px solid #49b05c !important;
  --item-border-bottom-focused: 1px solid #49b05c !important;
  --item-font-weight: 700 !important;
  --item-font-size: 100px !important;
  span {
    input {
      height: 70px !important;
    }
  }
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  code-input {
    --item-spacing: 4px !important;
  }
}

.tour-highlighted {
  z-index: 10000001 !important;
  padding: 20px;
  border-radius: 8px !important;
  background-color: white !important;
  width: fit-content;
  border: 2px solid $primary-color;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.heading-status-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  app-status {
    font-size: 16px;
    line-height: 26px;
  }
}

.details-information-wrapper {
  margin-bottom: 15px;
  padding: 10px;
  border-bottom: 1px solid $border-gray-color;
  min-width: 280px;
  display: flex;
  gap: 20px;

  .details-information {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    font-size: 14px;

    .details-information-label {
      font-weight: 400;
      color: $text-gray-color;
    }

    .details-information-value {
      font-weight: 600;
      color: $text-black-color;
    }

    .details-information-status-value {
      color: $success;
    }
    .details-information-status-inactive {
      color: $status-deactivation-background-color;
    }
  }
}

.color-success {
  color: $success;
}

.color-danger {
  color: $status-inactive-background-color;
}
