:root {
  --primary-color: #0052cc;
  --primary-light-color: #286dd4;
  --primary-light-color-2: #a5c2ed;
  --primary-ultra-light-color: #f4f8fc;
}

$primary-color: var(--primary-color);
$primary-light-color: var(--primary-light-color);
$primary-light-color-2: var(--primary-light-color-2);
$primary-ultra-light-color: var(--primary-ultra-light-color);

$text-color: #adb5bd;
$text-secondary-color: #606d76;

$text-gray-light-color: #68788e;
$text-gray-dark-color: #363636;

$text-black-color: #2f3941;
$text-black-darker-color: #202223;

$page-background-color: #fdfdfd;
$white-page-background-color: #ffffff;

$background-color: #1c2331;
$active-background-color: #1b2331;
$background-gray-color: #f7f7f7;

$status-active-background-color: #37b24d;
$status-activation-background-color: #aee9d1;
$status-completed-background-color: #c6f1da;
$status-activation-indicator-color: #007f5f;
$status-completed-indicator-color: #0e4e30;

$status-pending-background-color: #adb5bd;
$status-pending-darker-background-color: #6a7178;

$status-inactive-background-color: #dc2020;
$status-deactivation-background-color: #d72c0d;
$status-deactivation-text-color: #fed3d1;
$status-creation-bg-color: #e4e5e7;

$success: #49b05c;

$text-gray-color: #68737d;
$border-gray-color: #ced4da;

$chip-color: #e9ebed;

$bg-gray-color: #6d7175;
$bg-light-gray-color: #f5f7f9;

$demo-button-background-color: #7c7a7b;
$demo-button-active-background-color: #363636;

$light-gray-color: #606060;
$dotted-gray-border-color: #cfd6dd;
$sub-heading-color: #272e35;

// summary cards
$background-color-green: #b5e4ca40;
$background-color-blue: #b1e5fc40;
$background-color-purple: #cabdff40;
$background-color-red: #d900003b;

//
$green-light-color: #f4f9f6;

//shadow
$shadow-color: #00000014;
$black: #000000;

$scanner-not-connected: #dc2626;
$scanner-connected: #29823b;

$override-blue: #d7e4ff;
$override-dark-blue: #3062d4;

$warning-color: #ffe380;

$incident-error-color: #ffecec;
$incident-warning-color: #fff8e5;
